<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu" class="side-nav">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItems">
        <li class="menu-title" v-if="item.isTitle" :key="item.id">
          {{ $t(item.label) }}
        </li>
        <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{
              'has-arrow': !item.badge,
              'has-dropdown': item.badge
            }"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="
                `badge badge-pill badge-${item.badge.variant} float-right`
              "
              v-if="item.badge"
              >{{ $t(item.badge.text) }}</span
            >
          </a>

          <router-link
            :to="{ name: item.routeName }"
            v-if="!hasItems(item)"
            :class="
              `side-nav-link-ref ${
                item.routeName === $route.name ? 'active' : ''
              }`
            "
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="
                `badge badge-pill badge-${item.badge.variant} float-right`
              "
              v-if="item.badge"
              >{{ $t(item.badge.text) }}</span
            >
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                :to="{ name: subitem.routeName }"
                v-if="!hasItems(subitem)"
                :class="
                  `side-nav-link-ref ${
                    subitem.routeName === $route.name ? 'active' : ''
                  }`
                "
                ><span>{{ $t(subitem.label) }}</span
                ><span
                  :class="
                    `badge badge-pill badge-${subitem.badge.variant} float-right`
                  "
                  v-if="subitem.badge"
                  >{{ $t(subitem.badge.text) }}</span
                ></router-link
              >
              <a
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                >{{ $t(subitem.label) }}</a
              >
              <ul
                v-if="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li
                  v-for="(subSubitem, index) of subitem.subItems"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subSubitem.routeName }"
                    class="side-nav-link-ref"
                    ><span>{{ $t(subSubitem.label) }}</span></router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<script>
import MetisMenu from "metismenujs/dist/metismenujs";

export default {
  mounted: function() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {
    menuItems() {
      return [
        {
          label: "dashboard",
          icon: "bxs-dashboard",
          routeName: "AdminDashboard"
        },
        {
          label: "clients",
          icon: "bx-user",
          routeName: "AdminUsers"
        },
        {
          label: "books",
          icon: "bx-book-open",
          subItems: [
            {
              label: "view-books",
              routeName: "AdminBooks"
            },
            {
              label: "add-book",
              routeName: "AdminAddBook"
            },
            {
              label: "ISBN Manager",
              icon: "bx-book-content",
              routeName: "IsbnManager"
            },
            {
              label: "export",
              icon: "bx-export",
              routeName: "ExportBooks"
            },
            {
              label: "import",
              icon: "bx-import",
              routeName: "ImportBooks"
            }
          ]
        },
        {
          label: "promotions",
          icon: "bx-store",
          subItems: [
            {
              label: "view-promotions",
              routeName: "AdminViewPromotions",
              badge: {
                variant: "primary",
                text: this.$store.getters["promotions/getRequested"]
              }
            },
            {
              label: "new-promotion",
              routeName: "AdminAddPromotion"
            }
          ]
        },
        {
          label: "sales",
          icon: "bx-basket",
          subItems: [
            {
              label: "monthly-imports",
              routeName: "ViewSaleImports"
            },
            {
              label: "monthly-records",
              routeName: "ViewSaleRecords"
            },
            {
              label: "daily-imports",
              routeName: "ViewDailySaleImports"
            },
            {
              label: "daily-records",
              routeName: "ViewDailySaleRecords"
            }
          ]
        },
        {
          label: "royalties",
          icon: "bx-money",
          subItems: [
            {
              label: "view-royalty-records",
              routeName: "ViewRoyalties"
            },
            {
              label: "view-royalty-payments",
              routeName: "ViewRoyaltyPayments"
            },
            {
              label: "add-royalties",
              routeName: "AddRoyalties"
            }
          ]
        },
        {
          label: "payments",
          icon: "bx-dollar",
          subItems: [
            {
              label: "view-payments",
              routeName: "ViewPayments"
            },
            {
              label: "add-payment",
              routeName: "AddPayment"
            },
            {
              label: "add-batch-payments",
              routeName: "AddBatchPayments"
            }
          ]
        },
        {
          label: "reports",
          icon: "bx-bar-chart-square",
          subItems: [
            {
              label: "sales-by-channel",
              routeName: "AdminSalesByChannel"
            },
            {
              label: "sales-by-title",
              routeName: "AdminSalesByTitle"
            },
            {
              label: "sales-by-territory",
              routeName: "AdminSalesByTerritory"
            },
            {
              label: "daily-eBook-sales-report",
              routeName: "AdminDailySalesReport"
            },
            {
              label: "royalty-report",
              routeName: "AdminRoyaltyReport"
            }
          ]
        },
        {
          label: "system",
          icon: "bx-slider",
          subItems: [
            {
              label: "site-settings",
              routeName: "AdminSiteSettings"
            },
            {
              label: "sales-channels",
              routeName: "AdminSalesChannels"
            },
            {
              label: "categories",
              routeName: "AdminCategories"
            },
            {
              label: "languages",
              routeName: "AdminLanguages"
            },
            {
              label: "currencies",
              routeName: "AdminCurrencies"
            },
            {
              label: "territories",
              routeName: "AdminTerritories"
            }
          ]
        },
        {
          label: "support",
          icon: "bx-support",
          routeName: "AdminSupport"
        }
      ].map((item, i) => ({
        ...item,
        id: i,
        subItems: (item.subItems || []).map((subitem, j) => ({
          ...subitem,
          id: j,
          parentId: i
        }))
      }));
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    }
  }
};
</script>
