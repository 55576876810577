var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-nav",attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_vm._l((_vm.menuItems),function(item){return [(item.isTitle)?_c('li',{key:item.id,staticClass:"menu-title"},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]):_vm._e(),(!item.isTitle && !item.isLayout)?_c('li',{key:item.id},[(_vm.hasItems(item))?_c('a',{staticClass:"is-parent",class:{
            'has-arrow': !item.badge,
            'has-dropdown': item.badge
          },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:`bx ${item.icon}`}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),(item.badge)?_c('span',{class:`badge badge-pill badge-${item.badge.variant} float-right`},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),(!_vm.hasItems(item))?_c('router-link',{class:`side-nav-link-ref ${
              item.routeName === _vm.$route.name ? 'active' : ''
            }`,attrs:{"to":{ name: item.routeName }}},[(item.icon)?_c('i',{class:`bx ${item.icon}`}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),(item.badge)?_c('span',{class:`badge badge-pill badge-${item.badge.variant} float-right`},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",attrs:{"aria-expanded":"false"}},_vm._l((item.subItems),function(subitem,index){return _c('li',{key:index},[(!_vm.hasItems(subitem))?_c('router-link',{class:`side-nav-link-ref ${
                  subitem.routeName === _vm.$route.name ? 'active' : ''
                }`,attrs:{"to":{ name: subitem.routeName }}},[_c('span',[_vm._v(_vm._s(_vm.$t(subitem.label)))]),(subitem.badge)?_c('span',{class:`badge badge-pill badge-${subitem.badge.variant} float-right`},[_vm._v(_vm._s(_vm.$t(subitem.badge.text)))]):_vm._e()]):_vm._e(),(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,index){return _c('li',{key:index},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":{ name: subSubitem.routeName }}},[_c('span',[_vm._v(_vm._s(_vm.$t(subSubitem.label)))])])],1)}),0):_vm._e()],1)}),0):_vm._e()],1):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }